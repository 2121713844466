// Styles
import '../scss/main.scss';

// Scripts
import { offer } from '@shop/frontend/dist/desktop/index';

$(document).ready(function () {
  offer.getState(function (data) {
    if (data.query.cmp === 'false') {
      $('.cmp-hide').hide();
      $('.cmp-show').show();
      $('body').addClass('cmp-clear');
    }
  });

  let stickyOffset = $('#header').offset().top;
  $(window).scroll(function () {
    let sticky = $('#header'),
      scroll = $(window).scrollTop();
    if (scroll >= stickyOffset) sticky.addClass('fixed');
    else sticky.removeClass('fixed');
  });
});
